import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import ImageProfile from "../components/imageProfile"
import SEO from "../components/seo"

class IndexPage extends Component {
  render() {
    return (
      <div>
        <SEO
          title="Learn ReasonML"
          keywords={[`reasonml`, `reason`, `functional programming`, `ocaml`]}
        />
        <div className="container">
          <div className="parent">
            <div className="header">
              <div className="title"> Learn ReasonML </div>
              <div className="subtitle"> a free video tutorial </div>
              <div className="subtitle"> by </div>
              <div className="nameWrapper">
                <div className="name"> David Kopal </div>
                <div className="profile-image">
                  <ImageProfile />
                </div>
              </div>
              <div>
                <span className="socialLink">
                  <a
                    href="https://twitter.com/coding_lawyer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    @coding_lawyer
                  </a>
                </span>
                <span className="socialLink">
                  <a
                    href="https://codinglawyer.io"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    codinglawyer.io
                  </a>
                </span>
              </div>
            </div>
            <div className="reason-logo">
              <Image />
            </div>
          </div>
        </div>
        <div className="links">
          {this.props.data.allVideos.edges.map(video => {
            return (
              <div key={video.node.id}>
                {video.node.id === "3PjPGEoSYFA" && (
                  <div className="video-title">1. Basic data types</div>
                )}
                {video.node.id === "yhxgNtuyySs" && (
                  <div className="video-title">2. Basic data structures</div>
                )}
                {video.node.id === "8CLBYWzjD04" && (
                  <div className="video-title">3. Advanced data structures</div>
                )}
                {video.node.id === "XKH-4Fy3WW0" && (
                  <div className="video-title">4. Higher-order functions</div>
                )}
                {video.node.id === "BHqK6qX7Rbc" && (
                  <div className="video-title">5. Imperative features</div>
                )}
                <div key={video.node.id} className="link">
                  <a href={`videos/${video.node.slug}`}>{video.node.title}</a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allVideos {
      edges {
        node {
          id
          title
          slug
          description
        }
      }
    }
  }
`
